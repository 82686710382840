const hamburgerButton = document.getElementById("hamburgerButton");
const nav = document.getElementById("mainNav");
const navigationLinks = document.querySelectorAll("#mainNav a");

hamburgerButton.addEventListener("click", () => {
  if (nav.dataset.state === "open") {
    nav.style.right = "-100%";
    nav.dataset.state = "closed";
    hamburgerButton.classList.remove("is-active");
  } else {
    nav.style.right = 0;
    nav.dataset.state = "open";
    hamburgerButton.classList.add("is-active");
  }
});

nav.addEventListener("click", (event) => {
  if (window.innerWidth > 1023) return false;

  if (event.target.closest("a") !== null) {
    nav.style.right = "-100%";
    nav.dataset.state = "closed";
    hamburgerButton.classList.remove("is-active");
  }
});

window.addEventListener("resize", () => {
  if (window.innerWidth > 1023) {
    nav.style.right = "inherit";
    nav.dataset.state = "closed";
  } else {
    nav.style.right = "-100%";
    nav.dataset.state = "closed";
  }
});

// The debounce function receives our function as a parameter
const debounce = (fn) => {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params);
    });
  };
};

// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const storeScroll = () => {
  document.documentElement.dataset.scroll = window.scrollY;
};

// Listen for new scroll events, here we debounce our `storeScroll` function
document.addEventListener("scroll", debounce(storeScroll), { passive: true });

// Update scroll position for first time
storeScroll();
